//
// Side Menu Controller
//

import { getElementInteger, getElementString, onClick, onLoaded, setElementAttribute } from '../utilities'

export default class SideMenuController {
  public static readonly shared = new SideMenuController()

  /// Returns the next available index.
  private nextAvailableIndex = 1

  // MARK: - Indexes

  private nextIndex() {
    return this.nextAvailableIndex++
  }

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.buildSideMenus.bind(this))
    onClick('.side-menu-toggle, .side-menu-toggle *', this.openSideMenu.bind(this))
    onClick('.side-menu', this.closeSideMenu.bind(this), false)
    onClick('.side-menu .side-menu__header .side-menu__header__close', this.closeSideMenu.bind(this))
    onClick('.side-menu .side-menu__header .side-menu__header__back', this.popTrackItem.bind(this))
    onClick('.side-menu [data-side-menu-track-target]', this.pushTrackItem.bind(this))
  }

  // MARK: - Setup

  private buildSideMenus() {
    document.querySelectorAll('.side-menu').forEach((sideMenuElement) => {
      if (sideMenuElement instanceof HTMLElement === false) return

      this.buildSideMenu(sideMenuElement)
    })
  }

  private buildSideMenu(sideMenuElement: HTMLElement) {
    const sourceSelector = getElementString(sideMenuElement, 'data-side-menu-source')
    if (sourceSelector == null) return

    const sourceElement = document.querySelector(sourceSelector)
    if (sourceElement instanceof HTMLElement === false) return

    const headerTemplateElement = this.findTemplateElement('header', sideMenuElement)
    if (headerTemplateElement == null) return

    const panelElement = document.createElement('div')
    panelElement.classList.add('side-menu__panel')
    sideMenuElement.appendChild(panelElement)

    const trackElement = document.createElement('div')
    trackElement.classList.add('side-menu__track')
    panelElement.appendChild(trackElement)

    const trackItemIndex = this.nextIndex()
    const listElement = sourceElement.cloneNode(true) as HTMLElement
    const quickLinksTemplateElement = this.findTemplateElement('quick-links', sideMenuElement)
    const toolbarElements = [
      this.buildRegionsTrackItem(sideMenuElement, trackElement, headerTemplateElement),
      this.buildLanguagesTrackItem(sideMenuElement, trackElement, headerTemplateElement)
    ].filter((element) => element instanceof HTMLElement)

    this.buildTrackItem(
      0,
      trackItemIndex,
      trackElement,
      listElement,
      null,
      headerTemplateElement,
      quickLinksTemplateElement,
      toolbarElements
    )
  }

  private buildLanguagesTrackItem(
    sideMenuElement: HTMLElement,
    trackElement: HTMLElement,
    headerTemplateElement: HTMLTemplateElement
  ) {
    const selector = getElementString(sideMenuElement, 'data-side-menu-languages-source')
    if (selector == null) return null

    const title = getElementString(sideMenuElement, 'data-side-menu-languages-title')
    if (title == null) return null

    const languagesElement = document.querySelector(selector)
    if (languagesElement instanceof HTMLElement === false) return null

    const currentAnchorElement = languagesElement.querySelector(':scope > a')
    if (currentAnchorElement instanceof HTMLAnchorElement === false) return null

    const trackItemIndex = this.nextIndex()
    const trackItemElement = document.createElement('div')
    trackItemElement.id = `side-menu-track-item-${trackItemIndex}`
    trackItemElement.classList.add('side-menu__track__item')
    trackItemElement.style.left = '100%'
    setElementAttribute(trackItemElement, 'data-side-menu-depth', '1')
    trackElement.appendChild(trackItemElement)

    const headerFragment = headerTemplateElement.content.cloneNode(true) as DocumentFragment
    const headerElement = headerFragment.firstElementChild
    if (headerElement instanceof HTMLElement) {
      trackItemElement.appendChild(headerElement)

      const titleElement = headerElement.querySelector('a.side-menu__header__title')
      if (titleElement instanceof HTMLAnchorElement) {
        titleElement.href = '#'
        titleElement.textContent = title
      }
    }

    const listElement = document.createElement('ul')
    listElement.id = `side-menu-list-${trackItemIndex}`
    listElement.role = 'menu'
    trackItemElement.appendChild(listElement)

    const addListItemElement = (anchorElement: HTMLAnchorElement, isCurrent: boolean) => {
      const clonedAnchorElement = anchorElement.cloneNode(true) as HTMLAnchorElement
      clonedAnchorElement.classList.add('side-menu__language')
      if (isCurrent) clonedAnchorElement.classList.add('side-menu__language--current')

      const listItemElement = document.createElement('li')
      listItemElement.role = 'menuitem'
      listItemElement.appendChild(clonedAnchorElement)
      listElement.appendChild(listItemElement)
    }

    addListItemElement(currentAnchorElement, true)

    const anchorElements = languagesElement.querySelectorAll(':scope > .sub-menu > li > a')
    anchorElements.forEach((anchorElement) => addListItemElement(anchorElement as HTMLAnchorElement, false))

    const sideMenuButtonElement = document.createElement('button')
    sideMenuButtonElement.classList.add('side-menu__language-button')
    sideMenuButtonElement.type = 'button'
    sideMenuButtonElement.innerHTML = currentAnchorElement.innerHTML
    setElementAttribute(sideMenuButtonElement, 'data-side-menu-track-target', `#side-menu-track-item-${trackItemIndex}`)

    return sideMenuButtonElement
  }

  private buildRegionsTrackItem(
    sideMenuElement: HTMLElement,
    trackElement: HTMLElement,
    headerTemplateElement: HTMLTemplateElement
  ) {
    const selector = getElementString(sideMenuElement, 'data-side-menu-regions-source')
    if (selector == null) return null

    const regionsElement = document.querySelector(selector)
    if (regionsElement instanceof HTMLElement === false) return null

    const regionsListElement = regionsElement.querySelector('ul.regions__list')
    if (regionsListElement instanceof HTMLUListElement === false) return null

    const buttonElement = regionsElement.querySelector('button.regions__button')
    if (buttonElement instanceof HTMLButtonElement === false) return null

    const anchorElements = regionsElement.querySelectorAll('a.regions__region')
    if (anchorElements.length < 1) return null

    const trackItemIndex = this.nextIndex()
    const trackItemElement = document.createElement('div')
    trackItemElement.id = `side-menu-track-item-${trackItemIndex}`
    trackItemElement.classList.add('side-menu__track__item')
    trackItemElement.style.left = '100%'
    setElementAttribute(trackItemElement, 'data-side-menu-depth', '1')
    trackElement.appendChild(trackItemElement)

    const headerFragment = headerTemplateElement.content.cloneNode(true) as DocumentFragment
    const headerElement = headerFragment.firstElementChild
    if (headerElement instanceof HTMLElement) {
      trackItemElement.appendChild(headerElement)

      const title = getElementString(regionsListElement, 'aria-label')
      if (title && title.length > 0) {
        const titleElement = headerElement.querySelector('a.side-menu__header__title')
        if (titleElement instanceof HTMLAnchorElement) {
          titleElement.href = '#'
          titleElement.textContent = title
        }
      }
    }

    const listElement = document.createElement('ul')
    listElement.id = `side-menu-list-${trackItemIndex}`
    listElement.role = 'menu'
    trackItemElement.appendChild(listElement)

    let title = buttonElement.textContent

    anchorElements.forEach((anchorElement) => {
      const listItemElement = document.createElement('li')
      listItemElement.role = 'menuitem'
      listElement.appendChild(listItemElement)

      const sideMenuAnchorElement = anchorElement.cloneNode(true) as HTMLAnchorElement
      sideMenuAnchorElement.classList.remove('regions__region')
      sideMenuAnchorElement.classList.add('side-menu__region')

      if (sideMenuAnchorElement.classList.contains('regions__region--current')) {
        sideMenuAnchorElement.classList.remove('regions__region--current')
        sideMenuAnchorElement.classList.add('side-menu__region--current')

        const spanElement = sideMenuAnchorElement.querySelector('span')
        if (spanElement instanceof HTMLElement) title = spanElement.textContent
      }

      listItemElement.appendChild(sideMenuAnchorElement)
    })

    const sideMenuButtonElement = document.createElement('button')
    sideMenuButtonElement.classList.add('side-menu__region-button')
    sideMenuButtonElement.type = 'button'
    sideMenuButtonElement.textContent = title
    setElementAttribute(sideMenuButtonElement, 'data-side-menu-track-target', `#side-menu-track-item-${trackItemIndex}`)

    return sideMenuButtonElement
  }

  private buildTrackItem(
    depth: number,
    trackItemIndex: number,
    trackElement: HTMLElement,
    listElement: HTMLElement,
    anchorElement: HTMLAnchorElement | null,
    headerTemplateElement: HTMLTemplateElement,
    quickLinksTemplateElement: HTMLTemplateElement | null,
    toolbarElements: HTMLElement[] | null
  ) {
    const trackItemElement = document.createElement('div')
    trackItemElement.id = `side-menu-track-item-${trackItemIndex}`
    trackItemElement.classList.add('side-menu__track__item')
    trackItemElement.style.left = `${depth * 100}%`
    setElementAttribute(trackItemElement, 'data-side-menu-depth', depth)
    trackElement.appendChild(trackItemElement)

    const headerFragment = headerTemplateElement.content.cloneNode(true) as DocumentFragment
    const headerElement = headerFragment.firstElementChild
    if (headerElement instanceof HTMLElement) {
      trackItemElement.appendChild(headerElement)

      if (anchorElement) {
        const titleElement = headerElement.querySelector('a.side-menu__header__title')
        if (titleElement instanceof HTMLAnchorElement) {
          titleElement.rel = anchorElement.rel
          titleElement.href = getElementString(anchorElement, 'href') || '#'
          titleElement.title = anchorElement.title
          titleElement.innerHTML = anchorElement.innerHTML
        }
      }
    }

    listElement.id = `side-menu-list-${trackItemIndex}`
    listElement.role = 'menu'
    listElement.removeAttribute('class')
    listElement.removeAttribute('style')
    trackItemElement.appendChild(listElement)

    if (quickLinksTemplateElement) {
      const quickLinksFragment = quickLinksTemplateElement.content.cloneNode(true) as DocumentFragment
      const quickLinksElement = quickLinksFragment.firstElementChild
      if (quickLinksElement instanceof HTMLElement) {
        trackItemElement.appendChild(quickLinksElement)
      }
    }

    if (toolbarElements && toolbarElements.length > 0) {
      const spacerElement = document.createElement('div')
      spacerElement.classList.add('side-menu__spacer')
      trackItemElement.appendChild(spacerElement)

      const toolbarElement = document.createElement('div')
      toolbarElement.classList.add('side-menu__toolbar')
      toolbarElement.role = 'toolbar'
      toolbarElements.forEach((element) => toolbarElement.appendChild(element))
      trackItemElement.appendChild(toolbarElement)
    }

    listElement.querySelectorAll(':scope > li').forEach((listItemElement) => {
      if (listItemElement.classList.contains('menu-item--desktop')) {
        listItemElement.remove()
        return
      }

      listItemElement.role = 'menuitem'
      listItemElement.removeAttribute('id')
      listItemElement.removeAttribute('class')
      listItemElement.removeAttribute('style')

      const listItemAnchorElement = listItemElement.firstElementChild
      if (listItemAnchorElement instanceof HTMLAnchorElement === false) return

      const listItemListElement = listItemElement.lastElementChild
      if (listItemListElement instanceof HTMLUListElement === false) return

      listItemListElement.remove()

      const href = getElementString(listItemAnchorElement, 'href')
      if (href !== '#') {
        const childAnchorElements = listItemListElement.querySelectorAll(`:scope > li:only-child > a:only-child`)
        const childAnchorElement = childAnchorElements[0]
        if (childAnchorElement instanceof HTMLAnchorElement && childAnchorElement.href === href) return
      }

      const nextTrackItemIndex = this.nextIndex()

      setElementAttribute(
        listItemAnchorElement,
        'data-side-menu-track-target',
        `#side-menu-track-item-${nextTrackItemIndex}`
      )

      this.buildTrackItem(
        depth + 1,
        nextTrackItemIndex,
        trackElement,
        listItemListElement,
        listItemAnchorElement,
        headerTemplateElement,
        null,
        null
      )
    })
  }

  // MARK: - Helpers

  private findTemplateElement(name: string, sideMenuElement: HTMLElement): HTMLTemplateElement | null {
    const templateElement = sideMenuElement.querySelector(`template[data-side-menu-template="${name}"]`)
    if (templateElement instanceof HTMLTemplateElement === false) return null

    return templateElement
  }

  private findSideMenuElement(element: Element, customAttribute: string | null): HTMLElement | null {
    const attributes = ['data-side-menu-toggle']
    if (customAttribute) attributes.unshift(customAttribute)

    for (const attribute of attributes) {
      const selectorElement = element.closest(`[${attribute}]`)
      if (selectorElement) {
        const selector = getElementString(selectorElement, attribute)
        if (selector) return document.querySelector(selector)
      }
    }

    const sideMenuElement = element.closest('.side-menu')
    if (sideMenuElement instanceof HTMLElement) return sideMenuElement

    const referenceElement = element.closest('.side-menu-toggle')
    if (referenceElement) return document.getElementById('side-menu')

    return null
  }

  // MARK: - Actions

  public openSideMenu(element: Element) {
    const sideMenuElement = this.findSideMenuElement(element, 'data-side-menu-open')
    if (sideMenuElement == null) return

    sideMenuElement.classList.add('side-menu--open')

    document.querySelectorAll('.side-menu-toggle').forEach((element) => {
      element.setAttribute('aria-expanded', 'true')
    })
  }

  public closeSideMenu(element: Element) {
    const sideMenuElement = this.findSideMenuElement(element, 'data-side-menu-close')
    if (sideMenuElement == null) return

    sideMenuElement.classList.remove('side-menu--open')

    document.querySelectorAll('.side-menu-toggle').forEach((element) => {
      element.setAttribute('aria-expanded', 'false')
    })
  }

  public pushTrackItem(element: Element) {
    if (element instanceof HTMLElement === false) return

    const trackItemSelector = getElementString(element, 'data-side-menu-track-target')
    if (trackItemSelector == null) return

    const trackItemElement = document.querySelector(trackItemSelector)
    if (trackItemElement instanceof HTMLElement === false) return

    const trackElement = trackItemElement.closest('.side-menu__track')
    if (trackElement instanceof HTMLElement === false) return

    const sideMenuElement = trackElement.closest('.side-menu')
    if (sideMenuElement instanceof HTMLElement === false) return

    const depth = getElementInteger(trackItemElement, 'data-side-menu-depth')
    if (depth == null) return

    document
      .querySelectorAll(`.side-menu__track__item[data-side-menu-depth="${depth}"]`)
      .forEach((trackItemElement) => {
        if (trackItemElement instanceof HTMLElement === false) return

        trackItemElement.style.setProperty('z-index', '-1')
      })

    setElementAttribute(trackElement, 'data-side-menu-depth', depth)
    trackItemElement.style.setProperty('z-index', '1000')
    trackElement.style.setProperty('transform', `translateX(-${depth * 100}%)`)
  }

  public popTrackItem(element: Element) {
    if (element instanceof HTMLElement === false) return

    const trackElement = element.closest('.side-menu__track')
    if (trackElement instanceof HTMLElement === false) return

    let depth = getElementInteger(trackElement, 'data-side-menu-depth')
    if (depth == null || depth < 1) return

    depth -= 1
    setElementAttribute(trackElement, 'data-side-menu-depth', depth)
    trackElement.style.setProperty('transform', `translateX(-${depth * 100}%)`)
  }
}
