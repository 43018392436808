//
// Site Navigation Controller
//

import { onClick, onLoaded, onResize, onScroll, scrollToYOffset } from '../utilities'
import { Debounce } from '../debounce'

export default class SiteNavigationController {
  public static readonly shared = new SiteNavigationController()

  /// Returns the number of pixels the page needs to scroll in the opposite direction before the site navigation
  /// changes between sticky and non-sticky.
  public static readonly bufferYOffset = 100

  /// Returns the debounce used when the overlay needs to be hidden.
  private readonly hideOverlayDebounce = new Debounce(500)

  /// Returns the admin bar element, or `null` if it hasn't been found.
  private adminBarElement: HTMLElement | null = null

  /// Returns the site navigation element, or `null` if it hasn't been found.
  private element: HTMLElement | null = null

  /// Returns `true` when the site navigation element is sticky and visible, `false` when it's sticky but not visible,
  /// and `null` when it's not sticky.
  private sticky: boolean | null = null

  /// Returns the last `y` offset before the user changed the scroll direction, or the last cached offset if the user
  /// hasn't changed the scroll direction.
  private referenceYOffset = 0

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(() => {
      this.adminBarElement = document.getElementById('wpadminbar')
      this.element = document.querySelector('.site-nav')
      this.updateStickyState()
      this.updateOverlayVisibility()
      this.addEventListeners()
    })
  }

  // MARK: - Getters

  public isSticky() {
    return this.sticky
  }

  public getElement() {
    return this.element
  }

  public getAdminBarElement() {
    return this.adminBarElement
  }

  // MARK: - Event Listeners

  private addEventListeners() {
    onResize(this.updateOverlayVisibility.bind(this))
    onResize(this.updateStickyState.bind(this))
    onScroll(this.updateStickyState.bind(this))
    onClick('.site-nav .menu-item.menu-item--search > a[href="#"]', this.toggleSearchDropdown.bind(this))

    document
      .querySelectorAll('.site-nav .menu.menu--primary > .menu-item.menu-item-has-children')
      .forEach((menuItemElement) => {
        menuItemElement.addEventListener('mouseenter', this.didReceiveMouseEnterForMenuItem.bind(this))
        menuItemElement.addEventListener('mouseleave', this.didReceiveMouseLeaveForMenuItem.bind(this))
      })

    // When the search results popup is shown or hidden, we need to update the overlay visibility.
    document.querySelectorAll('.asp_main_container').forEach((searchContainerElement) => {
      searchContainerElement.addEventListener('asp_results_show', this.updateOverlayVisibility.bind(this))
      searchContainerElement.addEventListener('asp_results_hide', this.updateOverlayVisibility.bind(this))
    })
  }

  // MARK: - Overlay

  private needsOverlayVisible() {
    // If a menu item with children in the primary menu is being hovered, the overlay needs to be visible.
    if (document.querySelector('.site-nav .menu.menu--primary > .menu-item.menu-item-has-children:hover')) return true

    // If the search results popup is visible, the overlay needs to be visible.
    if (document.querySelector('.asp_r_1_1.asp_an_fadeInDrop')) return true

    // If the mobile search dropdown is visible, the overlay needs to be visible.
    if (window.innerWidth < 992 && document.querySelector('.site-nav.site-nav--searching')) return true

    return false
  }

  private updateOverlayVisibility() {
    // We're using a timeout to prevent the JavaScript engine from executing this code as a microtask and to ensure the
    // UI has been updated before determining if the overlay needs to be visible.
    setTimeout(() => {
      document.body.classList.toggle('body--overlay', this.needsOverlayVisible())
    }, 0)
  }

  // MARK: - Sticky

  private updateStickyState() {
    if (this.element === null) return

    if (this.element.classList.contains('site-nav--searching')) {
      if (this.sticky !== null) {
        this.sticky = null
        this.referenceYOffset = 0
        this.updateStickyStyles()
      }
    } else {
      const yOffset = window.pageYOffset

      if (this.sticky === null) {
        this.sticky = yOffset > 0
        this.referenceYOffset = yOffset
        this.updateStickyStyles()
      } else if (yOffset <= 0) {
        if (this.sticky !== false) {
          this.sticky = false
          this.referenceYOffset = 0
          this.updateStickyStyles()
        }
      } else if (this.sticky) {
        if (yOffset <= this.referenceYOffset) {
          this.referenceYOffset = yOffset
        } else if (yOffset > this.referenceYOffset + SiteNavigationController.bufferYOffset) {
          this.sticky = false
          this.referenceYOffset = yOffset
          this.updateStickyStyles()
        }
      } else if (yOffset >= this.referenceYOffset) {
        this.referenceYOffset = yOffset
      } else if (yOffset < this.referenceYOffset - SiteNavigationController.bufferYOffset) {
        this.sticky = true
        this.referenceYOffset = yOffset
        this.updateStickyStyles()
      }
    }
  }

  private updateStickyStyles() {
    if (this.element === null) return

    setTimeout(() => {
      const event = new CustomEvent('site-nav:updated', { bubbles: true })
      this.element?.dispatchEvent(event)
    }, 0)

    if (this.sticky === null) {
      this.element.style.removeProperty('position')
      this.element.style.removeProperty('top')
      this.element.classList.remove('site-nav--box-shadow')
      return
    }

    let top = 0

    if (this.adminBarElement) {
      const { height, position } = getComputedStyle(this.adminBarElement)
      if (position === 'fixed') top += parseFloat(height)
    }

    if (this.sticky === false) {
      const { height } = getComputedStyle(this.element)
      top -= parseFloat(height)
    }

    this.element.style.setProperty('position', 'sticky')
    this.element.style.setProperty('top', `${top}px`)
    this.element.classList.toggle('site-nav--box-shadow', this.sticky || false)

    if (this.sticky) return

    this.element.querySelectorAll('input').forEach((inputElement) => {
      if (inputElement instanceof HTMLInputElement === false) return

      inputElement.blur()
    })
  }

  // MARK: - Search

  private toggleSearchDropdown(_referenceElement: Element) {
    if (this.element instanceof HTMLElement === false) return

    if (this.element.classList.toggle('site-nav--searching')) {
      this.sticky = null
      this.referenceYOffset = 0

      if (this.adminBarElement) {
        const { height, position } = getComputedStyle(this.adminBarElement)
        if (position !== 'fixed') this.referenceYOffset += parseFloat(height)
      }

      scrollToYOffset(this.referenceYOffset, this.updateStickyStyles.bind(this))
    } else {
      const closeElement = this.element.querySelector('.asp_w_container .proclose')
      if (closeElement instanceof HTMLElement) closeElement.click()

      this.updateStickyState()
    }

    this.updateOverlayVisibility()
  }

  // MARK: - Menu Items

  private didReceiveMouseEnterForMenuItem(_event: Event) {
    this.hideOverlayDebounce.cancel()
    this.updateOverlayVisibility()
  }

  private didReceiveMouseLeaveForMenuItem(_event: Event) {
    if (this.element instanceof HTMLElement === false) return

    const menuElement = this.element.querySelector('.menu.menu--primary:hover')
    if (menuElement) {
      this.hideOverlayDebounce.perform(this.updateOverlayVisibility.bind(this))
    } else {
      this.updateOverlayVisibility()
    }
  }
}
